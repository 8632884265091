@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.heroBackgroundImage {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 0;
}
.heroBackgroundImage.animate {
  animation: 1s ease-in-out 0s 1 normal forwards fade-in;
}

.ifAllahIntends {
  margin-block-start: 0;
  margin-block-end: 12px;
  margin-top: 60px;
  font-family: var(--font-Fraunces);
  font-weight: 400;
  font-size: 2.43rem;
}
.heGivesHim {
  margin: 0;
  font-family: var(--font-Fraunces);
  font-weight: 400;
  font-size: 2.43rem;
}
.ifAllahIntendsContainer,
.sahhAlBukhari71 {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.ifAllahIntendsContainer {
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.sahhAlBukhari71 {
  font-size: 0.8rem;
  font-weight: 600;
  font-family: var(--font-Fraunces);
  font-weight: 400;
  font-size: 19px;
}
.heroContent {
  margin: 0 !important;
  position: absolute;
  width: 67.29%;
  top: 2.88rem;
  right: 16.39%;
  left: 16.32%;
  height: 7.38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.56rem;
  opacity: 0;
  z-index: 1;
}
.heroContent.animate {
  animation: 1s ease-in 0s 1 normal forwards slide-in-right;
}
.contactFormSection,
.heroSection {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.heroSection {
  height: 34.69rem;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: 1.69rem;
  color: var(--color-black);
  font-family: var(--font-domine);
}

.contactFormSection {
  background-color: var(--color-black);
  align-items: flex-start;
  padding: 2.75rem 6.31rem;
}
.copyrightStatement {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: var(--font-playfair-display);
  font-weight: 400;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  flex-shrink: 0;
}
.allRightsReserved {
  display: block;
}
.allRightsReservedContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 2.25rem;
  color: #999;
}
.copyright2022Container {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-mini);
  line-height: 1.69rem;
}
.copyrightSection,
.homepage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.copyrightSection {
  align-self: stretch;
  height: 15.31rem;
  flex-shrink: 0;
  justify-content: center;
  padding: 0 0.81rem;
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.homepage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 103.13rem;
  justify-content: flex-start;
  gap: 0.94rem;
  text-align: center;
  font-size: 1.88rem;
  color: var(--color-black);
  font-family: var(--font-playfair-display);
}
@media screen and (max-width: 1200px) {
  .heroBackgroundImage,
  .heroContent {
    opacity: 0;
  }
  .heroBackgroundImage.animate {
    animation: 1s ease-in-out 0s 1 normal forwards fade-in;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .heroContent.animate {
    animation: 1s ease-in 0s 1 normal forwards slide-in-right;
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
@media screen and (max-width: 960px) {
  .heroBackgroundImage,
  .heroContent {
    opacity: 0;
  }
  .heroBackgroundImage.animate {
    animation: 1s ease-in-out 0s 1 normal forwards fade-in;
  }

  .ifAllahIntends {
    font-size: 2rem;
  }
  .heGivesHim {
    font-size: 2rem;
  }
  .sahhAlBukhari71 {
    font-size: 1rem;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .heroContent.animate {
    animation: 1s ease-in 0s 1 normal forwards fade-in-right;
  }

  .contactFormSection {
    padding: 2.75rem 4rem;
  }
  @keyframes fade-in-right {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
@media screen and (max-width: 420px) {
  .ifAllahIntendsContainer {
    font-weight: 700;
    font-family: var(--font-domine);
    font-size: 0.87rem;
    text-align: center;
  }
  .sahhAlBukhari71 {
    font-size: 0.6rem;
  }

  .ifAllahIntends,
  .heGivesHim {
    /* white-space: nowrap; */
    font-size: 0.8rem;
    font-weight: 600;
  }

  .heroContent {
    opacity: 0;
  }
  .heroContent.animate {
    animation: 1s ease-in 0s 1 normal forwards slide-in-right;
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .contactFormSection {
    padding: 2.75rem 3.5rem;
  }

  .copyrightStatement {
    font-size: 1.4rem;
    height: 1.38rem;
  }

  .allRightsReservedContainer {
    font-size: 0.65rem;
    line-height: 1.44rem;
  }

  .copyright2022Container {
    font-size: var(--font-size-5xs);
  }
  .copyrightSection {
    max-width: 100%;
    text-align: center;
  }
  .copyrightSection,
  .homepage {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 375px) {
  .ifAllahIntends,
  .heGivesHim {
    font-size: 0.76rem;
    font-weight: 600;
  }

  .contactFormSection {
    padding: 2.75rem 3rem;
  }

  .ifAllahIntendsContainer {
    font-size: 0.79rem;
  }
  .sahhAlBukhari71 {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 320px) {
  .ifAllahIntends,
  .heGivesHim {
    white-space: normal;
    font-size: 0.653rem;
  }

  .ifAllahIntendsContainer {
    font-size: 0.6rem;
  }
  .sahhAlBukhari71 {
    font-size: 0.5rem;
  }

  .contactFormSection {
    padding: 2.75rem 2rem;
  }

  .copyrightStatement {
    font-size: 1.2rem;
    height: 1.38rem;
  }

  .allRightsReservedContainer {
    font-size: 0.4rem;
    line-height: 1.3rem;
  }
}
