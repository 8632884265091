.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark {
  background-color: #000;
  color: #fff;
}

.dark:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.light {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.light:hover {
  background-color: #000;
  color: #fff;
}

.grey {
  background-color: grey;
  opacity: 0.6;
  /* cursor: not-allowed; */
  color: black;
}

.grey:hover {
  background-color: grey;
}

.defaultWidth {
  width: 5rem;
}

.defaultHeight {
  height: 2rem;
}
