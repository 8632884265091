@import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georgia:ital,wght@1,400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-Fraunces: "Fraunces";
  --font-inter: Inter;
  --font-playfair-display: "Playfair Display";
  --font-georgia: Georgia;
  --font-domine: Domine;

  /* font sizes */
  --font-size-smi-7: 0.79rem;
  --font-size-6xs: 0.44rem;
  --font-size-sm: 0.88rem;
  --font-size-sm-1: 0.82rem;
  --font-size-2xs: 0.69rem;
  --font-size-mini: 0.94rem;
  --font-size-mid: 1.06rem;
  --font-size-smi: 0.81rem;
  --font-size-sm-8: 0.86rem;
  --font-size-sm-7: 0.86rem;
  --font-size-8xl-2: 1.7rem;
  --font-size-3xl: 1.38rem;
  --font-size-4xs: 0.56rem;
  --font-size-3xs: 0.63rem;
  --font-size-lg: 1.13rem;
  --font-size-5xs: 0.5rem;
  --font-size-sm-9: 0.87rem;
  --font-size-xs-3: 0.71rem;
  --font-size-2xs-8: 0.68rem;
  --font-size-xs-2: 0.7rem;
  --font-size-11xl-9: 1.93rem;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #1e2025;
  --color-gray-200: rgba(30, 32, 37, 0.01);
  --color-black: #000;
  --color-linen-100: #f2eee4;
  --color-linen-200: rgba(242, 238, 228, 0.49);
  --color-darkslategray: #373b4d;
  --color-darkgray-100: #a5a2a2;
  --color-hover: #cac6b8;

  /* Gaps */
  --gap-mid: 1.06rem;
  --gap-25xl: 2.75rem;
  --gap-8xs: 0.31rem;
  --gap-3xs: 0.63rem;
  --gap-10xs: 0.19rem;
  --gap-6xs: 0.44rem;
  --gap-xl: 1.25rem;
  --gap-95xl: 7.13rem;
  --gap-11xl: 1.88rem;
  --gap-0: 0rem;
  --gap-2xs: 0.69rem;
  --gap-smi: 0.81rem;
  --gap-12xs: 0.06rem;

  /* Paddings */
  --padding-11xl: 1.88rem;
  --padding-3xs: 0.63rem;
  --padding-xl: 1.25rem;
  --padding-3xl: 1.38rem;
  --padding-86xl: 6.56rem;
  --padding-16xl: 2.19rem;
  --padding-mini: 0.94rem;
  --padding-xs: 0.75rem;
  --padding-6xl: 1.56rem;
  --padding-6xs: 0.44rem;
  --padding-11xs: 0.13rem;
  --padding-xs-6: 0.73rem;
  --padding-5xs-7: 0.48rem;
}
