.wantToKnow {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 1.76rem;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.89rem;
  flex-shrink: 0;
}
.subscriptionemailinput {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscriptionemailinput {
  border: 2px solid var(--color-darkslategray);
  font-family: var(--font-georgia);
  font-size: var(--font-size-sm);
  background-color: transparent;
  box-sizing: border-box;
  width: 27.75rem;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-6xl);
}

.subscriptionemailinput:focus {
  outline: none;
  box-shadow: 0 0 2px 1px var(--color-darkslategray);
}

.buttonWidth {
  width: 4.5rem;
}

.buttonHeight {
  height: 3.5rem;
}

.subscriptionformdiv {
  width: 32.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.subscriptionSection {
  align-self: stretch;
  height: 9.38rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--color-black);
  font-family: var(--font-playfair-display);
}

@media screen and (max-width: 420px) {
  .wantToKnow {
    font-size: var(--font-size-smi);
    height: 1.13rem;
  }
  .subscriptionemailinput {
    width: 15.31rem;
    height: 2.06rem;
  }

  .buttonInSubscriptionForm {
    width: 15.31rem !important;
    height: 2.06rem !important;
  }

  .buttonInSubscriptionForm {
    width: 20rem;
    height: 20rem;
  }

  .subscriptionformdiv {
    flex-direction: column;
    gap: var(--gap-10xs);
    align-items: center;
    justify-content: center;
  }
  .subscriptionSection {
    height: 6.88rem;
    gap: var(--gap-3xs);
  }
}

@media screen and (max-width: 320px) {
  .wantToKnow {
    font-size: 0.7rem;
    height: 1.13rem;
  }
}
