@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: space-between;
  animation: fadeIn 0.5s ease-in forwards;
  margin-bottom: 5rem;
}

.leftColumn {
  flex: 1.2;
  font-family: "Playfair Display";
  padding-left: 7rem;
  padding-right: 13rem;
  display: flex;
  flex-direction: column;
}

.mainHeading {
  font-size: 3.2rem;
  font-weight: normal;
}

.firstParagraph {
  line-height: 2.2rem;
  color: rgb(39, 39, 39);
  opacity: 0.8;
}

.secondParagraph {
  margin-top: 2rem;
  line-height: 2.2rem;
  color: rgb(39, 39, 39);
  opacity: 0.8;
}

.middleColumn,
.rightColumn {
  flex: 1;
  margin-top: 3.5rem;
}

.emailButton {
  width: 11rem;
  height: 2.7rem;
}

.emailButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.emailLink {
  text-decoration: none;
}

.juzButton {
  width: 9rem;
  height: 2.1rem;
  font-family: "Avenir Light";
  margin-top: 1.5rem;
}

.upcomingText {
  font-size: 1.1rem;
  opacity: 0.7;
}

.progressText {
  color: grey;
}

@media (min-width: 421px) {
  .dropdown {
    display: none;
  }
}

@media (max-width: 420px) {
  .content {
    flex-direction: column;
  }

  .container {
    overflow-x: hidden;
  }

  .middleColumn,
  .rightColumn {
    padding: 0;
    display: none;
  }

  .leftColumn {
    padding: 0rem 2rem 0rem 2rem;
  }

  .dropdown {
    display: block;
    margin-bottom: 4rem;
    padding: 0.8rem 1.2rem;
    font-size: 1.2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.7);
    width: 75%;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Playfair Display";
    transition: 0.3s all ease-in-out;
  }

  .dropdown:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  }
}
